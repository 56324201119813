import {
  MessageStreamStore,
  ServicePayload,
  Transport,
  TransportAction,
  TransportSubAction,
} from "../types/general";
import store from "../store/basic-store";
import { AuthBody, GameStore, ZoneStore } from "../types";

let webSocket: WebSocket;

export const wait = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export async function getWebSocket(retry = 0): Promise<WebSocket> {
  if (!process.env.REACT_APP_SERVER_WS) {
    throw new Error("Invalid server");
  }
  if (webSocket && webSocket.readyState === webSocket.OPEN) {
    return webSocket;
  }
  return new Promise((resolve, reject) => {
    if (
      !webSocket ||
      webSocket.readyState === webSocket.CLOSING ||
      webSocket.readyState === webSocket.CLOSED
    ) {
      webSocket = new WebSocket(process.env.REACT_APP_SERVER_WS as string);
      attachToStore();
      wait(100).then(() => {
        getWebSocket(retry + 1)
          .then(resolve)
          .catch(reject);
      });
    }
    // else if (webSocket?.readyState === webSocket?.CONNECTING) {
    if (retry < 10) {
      wait(100).then(() => {
        getWebSocket(retry + 1)
          .then(resolve)
          .catch(reject);
      });
    } else {
      const error = new Error(`Server is down. Unable to connect.`);
      console.error(error);
      // If dev
      if (process.env.REACT_ENV === "UAT") {
        console.warn("error ws: ", process.env.REACT_APP_SERVER_WS);
      }
      if (store) {
        store.setApi({ key: "error", error });
      }
      reject(error);
    }
  });
}

// TODO Maybe pass in the handler
export async function attachToStore() {
  const ws = await getWebSocket();
  if (!ws.onmessage) {
    ws.onmessage = (ev: MessageEvent<any>): any => {
      handleStore(ev);
    };
    // Get auth from local storage
    const auth = localStorage.getItem("auth");
    const storeAuth = store.get("auth");
    if (auth) {
      try {
        const payload = JSON.parse(auth);
        await ws.send(
          JSON.stringify({
            action: TransportAction.AUTH,
            subAction: TransportSubAction.RE_AUTH,
            payload,
          })
        );
        if (!storeAuth) {
          store.setApi({ key: "auth", data: payload });
        }
      } catch (err) {
        console.warn("Unable to reinitialize auth");
        localStorage.setItem("auth", "");
      }
    } else {
      console.info("No Auth");
    }
  }
}

export function handleStore(messageEvent: MessageEvent<string>) {
  console.log("handleStore ====>");
  try {
    const { data } = messageEvent;
    const response: Transport = JSON.parse(data);
    const { error, payload, message, action, subAction } = response;
    if (message) {
      console.log("ev message: ", message);
    }
    console.log("action: ", action);
    console.log("payload: ", payload);
    switch (action) {
      case TransportAction.AUTH_RESPONSE:
        if (error) {
          store.setApi({ key: "auth", error });
        } else {
          localStorage.setItem("auth", JSON.stringify(payload));
          store.setApi({ key: "auth", data: payload });
          if (payload?.user) {
            store.set("user", payload.user);
          }
        }
        break;
      case TransportAction.ZONE_ACTION_RESPONSE:
        if (subAction === TransportSubAction.GET_MY_GAMES && payload?.games) {
          store.set("my-games", payload?.games);
        } else {
          handleZoneAction(payload as ZoneStore);
        }

        break;
      case TransportAction.GAME_ACTION_RESPONSE:
        handleGameAction(payload as GameStore);
        break;
      case TransportAction.MESSAGE_RESPONSE:
        store.set(
          `messages.ids_${(payload as MessageStreamStore).ids
            .sort()
            .join("-")}`,
          payload
        );
        break;
      case TransportAction.ERROR:
        console.log("ERROR: ", error);
        const { statusCode } = error || {};
        if (statusCode === 401) {
          store.setApi({ key: "error", error });
        }
        // else TODO
        break;
      default:
        console.log("Unhandled Message data: ", data);
        break;
    }
  } catch (err) {
    console.warn("handleStore Error: ", err);
  }
}

function handleZoneAction(payload: ZoneStore) {
  const { lobby, users } = payload;
  store.set("user-list", users);
  store.set(`${lobby.gameId}-lobby`, lobby);
}

function handleGameAction(payload: GameStore) {
  store.set(`game-${payload.id}`, payload);
}

export async function wsSend<T>(action: Transport<T>) {
  console.log("wsSend: ", action);
  const ws = await getWebSocket();
  const authPayload = store.get<ServicePayload<AuthBody>>("auth");
  if (authPayload?.data?.token) {
    action.auth = authPayload?.data?.token;
  }
  return ws.send(JSON.stringify(action));
}
