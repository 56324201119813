export type JsonObj = Record<string | number, any>;

export enum STATUS_CODE {
  EMAIL_IN_USE = "EMAIL_IN_USE",
  INVALID_INPUT = "INVALID_INPUT",
  PW_MISMATCH = "PW_MISMATCH",
  UNAUTHORIZED = "UNAUTHORIZED",
  UNKNOWN = "UNKNOWN",
  USERNAME_TAKEN = "USERNAME_TAKEN",
  USER_TOO_LONG = "USER_TOO_LONG",
}

export interface FNG_Error extends Error {
  statusCode?: number;
  errorCode?: STATUS_CODE;
  message: string;
  name: string;
}

export interface ServicePayload<T> {
  loading?: boolean;
  data?: T | null;
  error?: any;
}

export enum TransportSubAction {
  CREATE_GAME = "CREATE_GAME",
  EMAIL_CODE = "EMAIL_CODE",
  ERROR = "ERROR",
  FORFEIT = "FORFEIT",
  GET_GAME = "GET_GAME",
  GET_GAMES = "GET_ZONE",
  GET_MESSAGE = "GET_MESSAGE",
  GET_MY_GAMES = "GET_MY_GAMES",
  GET_ZONE = "GET_ZONE",
  JOIN_ZONE = "JOIN_ZONE",
  JOIN_GAME = "JOIN_GAME",
  LEAVE_ZONE = "LEAVE_ZONE",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  MESSAGE = "MESSAGE",
  RE_AUTH = "RE_AUTH",
  SIGNUP = "SIGNUP",
  START_GAME = "START_GAME",
  TAKE_TURN = "TAKE_TURN",
}

export enum TransportAction {
  AUTH = "AUTH",
  AUTH_RESPONSE = "AUTH_RESPONSE",
  ERROR = "ERROR",
  GAME_ACTION = "ROOM_ACTION",
  GAME_ACTION_RESPONSE = "ROOM_ACTION_RESPONSE",
  MESSAGE = "MESSAGE",
  MESSAGE_RESPONSE = "MESSAGE_RESPONSE",
  ROOM_ACTION = "ROOM_ACTION",
  ROOM_ACTION_RESPONSE = "ROOM_ACTION_RESPONSE",
  UNKNOWN = "UNKNOWN",
  ZONE_ACTION = "ZONE_ACTION",
  ZONE_ACTION_RESPONSE = "ZONE_ACTION_RESPONSE",
}

export interface Transport<T = JsonObj> {
  auth?: string;
  action: TransportAction;
  subAction?: TransportSubAction;
  message?: string;
  payload?: T;
  error?: FNG_Error;
}

export interface MessageTarget {
  personId?: string;
  zone?: string;
  area?: string;
  room?: string;
  path?: string;
}

export interface MessageStreamStore {
  lastUpdated: number;
  ids: number[];
  messages: MessagePayload[];
}

export interface MessagePayload {
  message?: string;
  timestamp: number;
  target: MessageTarget;
  source?: { username: string; id: number };
}
