import store from "../store/basic-store";
import {
  CreateGamePayload,
  ZoneId,
  JsonObj,
  MessagePayload,
  TransportAction,
  TransportSubAction,
  User,
} from "../types";
import { wsSend } from "./websocket";

export async function joinGameZoneAction(zone: ZoneId) {
  try {
    await wsSend({
      action: TransportAction.ZONE_ACTION,
      subAction: TransportSubAction.JOIN_ZONE,
      payload: { zone },
    });
  } catch (err) {
    // store.setApi({ key: "joinZone", error: (err as Error).message });
    throw new Error("Invalid JOIN_ZONE Action");
  }
}

export async function leaveGameZoneAction(zone: ZoneId) {
  try {
    await wsSend({
      action: TransportAction.ZONE_ACTION,
      subAction: TransportSubAction.LEAVE_ZONE,
      payload: { zone },
    });
  } catch (err) {
    // store.setApi({ key: "joinZone", error: (err as Error).message });
    throw new Error("Invalid LEAVE_ZONE Action");
  }
}

export async function sendGameInvite() {
  // {action: 'INVITE_TO_GAME', gameType: string; userId: string}
}

export async function createGame(payload: CreateGamePayload) {
  try {
    await wsSend({
      action: TransportAction.ZONE_ACTION,
      subAction: TransportSubAction.CREATE_GAME,
      payload,
    });
    await getMyGames();
  } catch (err) {
    // store.setApi({ key: "joinZone", error: (err as Error).message });
    throw new Error("Invalid CREATE_GAME Action");
  }
}

export async function getGames(gameId: ZoneId) {
  try {
    await wsSend({
      action: TransportAction.ZONE_ACTION,
      subAction: TransportSubAction.GET_GAMES,
      payload: { gameId },
    });
  } catch (err) {
    // store.setApi({ key: "joinZone", error: (err as Error).message });
    throw new Error("Invalid GET_GAMES Action");
  }
}

export async function joinGameAction(id: string, zone: ZoneId) {
  try {
    const user = store.get<User>("user");
    if (user) {
      const sending = await wsSend({
        action: TransportAction.ZONE_ACTION,
        subAction: TransportSubAction.JOIN_GAME,
        payload: { zone, id, userId: user.id },
      });
      await getMyGames();

      return sending;
    }
  } catch (err) {
    // store.setApi({ key: "joinZone", error: (err as Error).message });
    throw new Error("Invalid JOIN_GAME Action");
  }
}

export async function startGame(id: string, zone: ZoneId, options: JsonObj) {
  try {
    const user = store.get<User>("user");
    if (user) {
      return await wsSend({
        action: TransportAction.ZONE_ACTION,
        subAction: TransportSubAction.START_GAME,
        payload: { zone, id, options },
      });
    }
  } catch (err) {
    // store.setApi({ key: "joinZone", error: (err as Error).message });
    throw new Error("Invalid START_GAME Action");
  }
}

export async function getGame(id: string, zone: ZoneId) {
  try {
    await wsSend({
      action: TransportAction.GAME_ACTION,
      subAction: TransportSubAction.GET_GAME,
      payload: { zone, id },
    });
  } catch (err) {
    throw new Error("Invalid GET_GAME Action");
  }
}

export async function getMyGames() {
  try {
    await wsSend({
      action: TransportAction.ZONE_ACTION,
      subAction: TransportSubAction.GET_MY_GAMES,
      payload: {},
    });
  } catch (err) {
    throw new Error("Invalid GET_MY_GAMES Action");
  }
}

export async function takeTurnAction(
  gameId: string,
  zoneId: ZoneId,
  turn: JsonObj
) {
  try {
    await wsSend({
      action: TransportAction.GAME_ACTION,
      subAction: TransportSubAction.TAKE_TURN,
      payload: { zoneId, gameId, turn },
    });
  } catch (err) {
    throw new Error("Invalid TAKE_TURN Action");
  }
}

export async function forFeitAction(gameId: string, zoneId: ZoneId) {
  try {
    await wsSend({
      action: TransportAction.GAME_ACTION,
      subAction: TransportSubAction.FORFEIT,
      payload: { zoneId, gameId },
    });
  } catch (err) {
    throw new Error("Invalid FORFEIT Game Action");
  }
}

export async function sendGameMessageAction(
  gameId: string,
  zoneId: ZoneId,
  message: MessagePayload
) {
  try {
    await wsSend({
      action: TransportAction.GAME_ACTION,
      subAction: TransportSubAction.MESSAGE,
      payload: { zoneId, gameId, message },
    });
  } catch (err) {
    throw new Error("Invalid Game MESSAGE Action");
  }
}
