import Button from "antd/es/button";
import TextArea from "antd/es/input/TextArea";
import Layout from "antd/es/layout";
import { Content } from "antd/es/layout/layout";
import List from "antd/es/list";
import { MessagePayload, MessageStreamStore, MessageTarget } from "../../types";
import { useEffect, useRef, useState } from "react";
import { useOnDestroy, useOnLoad } from "../../hooks/lifecycle";
import { Flex } from "antd";

export function MessageBox({
  messageStream,
  sendMessageAction,
  target,
}: {
  messageStream: MessageStreamStore | null;
  sendMessageAction: (payload: MessagePayload) => void;
  target: MessageTarget;
}) {
  const messagesEndRef = useRef();
  const [message, setMessage] = useState("");

  useEffect(() => {
    // @ts-ignore
    messagesEndRef?.current?.scrollIntoView();
  }, [messageStream]);
  const listener = (e: KeyboardEvent) => {
    console.log("e code: ", e.code);
    console.log("e key: ", e.key);
    console.log("typing...", message);

    if (e.code === "Enter" && !!message.trim()) {
      console.log("sending...");

      sendMessageAction({
        message,
        timestamp: Date.now(),
        target,
      });
      e.stopImmediatePropagation();
      setMessage("");
    }
  };

  useOnLoad(() => {
    window.addEventListener("keypress", listener);
  });

  useOnDestroy(() => {
    window.removeEventListener("keypress", listener);
  });

  return (
    <Flex
      className="message"
      flex="1 1 auto"
      style={{ maxHeight: "calc(100% - 110px)" }}
    >
      <Layout>
        <Content
          style={{
            scrollbarColor: "green",
            overflowY: "scroll",
          }}
        >
          <List
            dataSource={messageStream?.messages}
            renderItem={(item) => {
              return item.source?.username && item.message ? (
                <Content style={{ textAlign: "left", padding: "0 1rem" }}>
                  <p>
                    <b>{item.source?.username}: </b>
                    {item.message}
                  </p>
                </Content>
              ) : null;
            }}
          />
          <div ref={messagesEndRef} />
        </Content>
        <Content>
          <TextArea
            name="message-input"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            allowClear
            autoSize={{ minRows: 1, maxRows: 5 }}
            style={{ margin: ".5rem 0" }}
          />

          <Button
            onClick={() => {
              const val = message;
              setMessage("");
              if (val) {
                sendMessageAction({
                  message: val,
                  timestamp: Date.now(),
                  target,
                });
              }
            }}
          >
            Send
          </Button>
        </Content>
      </Layout>
    </Flex>
  );
}
