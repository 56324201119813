import { useNavigate } from "react-router-dom";
import { User, ZoneId } from "../types";
import { useStore } from "../store/basic-store";
import Layout from "antd/es/layout";
import { Content, Header } from "antd/es/layout/layout";
import { ReactNode, useEffect } from "react";
import { BackHome } from "../components/back-home";
import Avatar from "antd/es/avatar";
import { UserOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { ZoneToName } from "../util/string";

export function ProfilePage() {
  const navigate = useNavigate();
  const user = useStore<User>("user");
  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user]);

  if (!user) {
    return <></>;
  }

  return (
    <Layout>
      <Header>
        <BackHome />
        <h3>Profile</h3>
      </Header>
      <Content style={{ overflowY: "scroll" }}>
        <ProfileSection title="Profile Image">
          <span>
            <Avatar size={64} icon={<UserOutlined />} />
          </span>
        </ProfileSection>

        <ProfileSection
          title="User Name"
          description="Sorry, no changing this."
        >
          <span style={{ textTransform: "capitalize" }}>{user.username}</span>
        </ProfileSection>

        <ProfileSection
          title="Player Names"
          description="You may choose a different player name per game type"
        >
          <p>
            <b>Default: </b>
            <span style={{ textTransform: "capitalize" }}>{user.username}</span>
          </p>
          {Object.keys(ZoneId).map((zone) => (
            <>
              <div>
                <b>{ZoneToName(zone as ZoneId)}</b>
              </div>
              {}
            </>
          ))}
        </ProfileSection>

        <ProfileSection title="Password">
          <span>*********</span>
          <Button>Update</Button>
        </ProfileSection>

        <ProfileSection title="Family / Group">
          {/*
            Account permissions
    - Primary Account
    - Adult Account
    - Child account
           */}
          <Button>Create</Button>
        </ProfileSection>

        <ProfileSection title="Alternate Login">
          <b>Facebook</b>
          <b>Google</b>
        </ProfileSection>

        <ProfileSection title="Friends">
          <p>
            <b>Mardok</b>
          </p>
        </ProfileSection>

        <ProfileSection title="Guilds">
          <p>Flaming Violet</p>
        </ProfileSection>
      </Content>
    </Layout>
  );
}

export function ProfileSection({
  children,
  description,
  title,
}: {
  children: ReactNode;
  description?: string;
  title: string;
}) {
  return (
    <section className="floater-box">
      <div>
        <h3>{title}</h3>
      </div>
      <div>{children}</div>
      {description ? (
        <>
          <Divider />
          <small>{description}</small>
        </>
      ) : null}
    </section>
  );
}
