import { LoginProps, SignUpPayload } from "../types/auth";
import store from "../store/basic-store";
import { wsSend } from "./websocket";
import { TransportAction, TransportSubAction } from "../types/general";

export async function loginAction(payload: LoginProps): Promise<void> {
  store.setApi({ key: "auth", loading: true });

  try {
    await wsSend({
      action: TransportAction.AUTH,
      subAction: TransportSubAction.LOGIN,
      payload,
    });
  } catch (err) {
    store.setApi({ key: "auth", error: (err as Error).message });
    throw new Error("Invalid Login");
  }
}

export async function signUpAction(payload: SignUpPayload): Promise<void> {
  store.setApi({ key: "auth", loading: true });

  try {
    await wsSend({
      action: TransportAction.AUTH,
      subAction: TransportSubAction.SIGNUP,
      payload,
    });
  } catch (err) {
    store.setApi({ key: "auth", error: (err as Error).message });
    throw new Error("Invalid Sign Up.");
  }
}

export async function logoutAction() {
  try {
    localStorage.setItem("auth", "");
    store.destroy();

    await wsSend({
      action: TransportAction.AUTH,
      subAction: TransportSubAction.LOGOUT,
    });
  } catch (err) {
    store.destroy();
    localStorage.setItem("auth", "");
  }
}

export async function sendEmailCode() {
  try {
    await wsSend({
      action: TransportAction.AUTH,
      subAction: TransportSubAction.EMAIL_CODE,
    });
    store.destroy();
    localStorage.setItem("auth", "");
  } catch (err) {
    store.destroy();
    localStorage.setItem("auth", "");
  }
}
