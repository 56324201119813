import { useEffect, useRef, useState } from "react";

export interface TimerParams {
  endTime: number;
  showSeconds?: boolean;
  callback?: () => void;
}

function getRem(endTime: number) {
  const now = Date.now();
  const timeLeft = now < endTime ? endTime - now : 0;
  return Math.floor(timeLeft / 1000);
}
export function useTimer(params: TimerParams) {
  const { endTime, callback } = params;
  const [count, setCount] = useState(0);
  const toId = useRef<null | NodeJS.Timeout>(null);

  useEffect(() => {
    if (toId.current) {
      clearInterval(toId.current);
      toId.current = null;
    }
    toId.current = setInterval(() => {
      const rem = getRem(endTime);
      setCount(rem);

      if (rem === 0) {
        if (callback) callback();
        if (toId.current) clearInterval(toId.current);
        toId.current = null;
      }
    }, 999);

    return () => {
      if (toId.current) {
        clearInterval(toId.current);
        toId.current = null;
      }
    };
  }, [endTime, callback]);

  return [count];
}

export function CountDownTimer(params: TimerParams) {
  const [count] = useTimer(params);

  return <span>{count}</span>;
}
