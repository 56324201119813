import { useEffect } from "react";

export function useOnLoad(callback: () => void) {
  useEffect(callback, []);
}

export function useOnDestroy(callback: () => void) {
  useEffect(() => {
    return callback;
  }, []);
}
