import React, { useEffect, useState } from "react";
import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { MainPage } from "./pages/main";
import { LoginPage } from "./pages/login";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import { SignUpPage } from "./pages/sign-up";
import { attachToStore } from "./service/websocket";
import { TicTacToeLobby } from "./games";
import store, { useStore } from "./store/basic-store";
import { Modal, ModalFuncProps } from "antd";
import { Transport, User } from "./types";
import { TicTacToeGameBoard } from "./games/tic-tac-toe/game-board";
import { MyGames } from "./pages/my-games";
import { getMyGames } from "./service/game.action";
import { ProfilePage } from "./pages/profile";

attachToStore();
const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/sign-up",
    element: <SignUpPage />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
  {
    path: "games/tic-tac-toe",
    element: <TicTacToeLobby />,
  },
  {
    path: "games/tic-tac-toe/game/:id",
    element: <TicTacToeGameBoard />,
  },
  {
    path: "/my-games",
    element: <MyGames />,
  },
]);

function App() {
  const myGames: { id: string }[] | null = useStore("my-games");
  const user = useStore<User>("user");

  useEffect(() => {
    if (user?.id && !myGames) {
      getMyGames();
    }
  }, [user, myGames]);
  return (
    <ErrorBoundary>
      <ErrorMessage />
      <RouterProvider router={router}></RouterProvider>
    </ErrorBoundary>
  );
}

export function ErrorMessage() {
  const [modal, contextHolder] = Modal.useModal();
  const errorPayload = useStore<Transport>("error");
  const [open, setOpen] = useState(!!errorPayload);
  console.log("ErrorMessage errorPayload: ", errorPayload);
  const onOk = () => {
    store.set("error", null);
    setOpen(false);
  };
  useEffect(() => {
    if (errorPayload?.error && !open) {
      setOpen(true);
      const { error } = errorPayload;
      console.log("error: ", error);
      const message = error.message || "Bad Things happened";
      console.log("message: ", message);
      const config: ModalFuncProps = {
        title: "Error",
        content: <div>{message}</div>,
        onOk,
      };
      switch (error.errorCode) {
        case "UNAUTHORIZED":
          config.title = error.name;
          config.content = <div>{error.message}</div>;
          config.onOk = () => {
            onOk();
            store.set("auth", null);
            localStorage.setItem("auth", "");
            // @ts-ignore
            window.location = "http://localhost:3000";
          };
          break;
        default:
      }

      modal.error(config);
    }
  }, [errorPayload]);
  return <>{contextHolder}</>;
}

export default App;
