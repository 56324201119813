import { GameLobbyLayout } from "../../components/game-layout";
import { ZoneId, GameLobbyStore } from "../../types";
import { LobbyLayout } from "../../components/lobby-layout";
import { useStore } from "../../store/basic-store";

export function TicTacToeLobby() {
  const lobby = useStore<GameLobbyStore>(`${ZoneId.TIC_TAC_TOE}-lobby`);
  console.log("lobbyResponse: ", lobby);
  return (
    <GameLobbyLayout id={ZoneId.TIC_TAC_TOE} name="Tic Tac Toe">
      <LobbyLayout games={lobby?.games || []} />
    </GameLobbyLayout>
  );
}
