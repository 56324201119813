import { Button, Card, Col, Layout, Modal, Row } from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { HeaderMenu } from "../components/header-menu";
import { useNavigate } from "react-router-dom";
import store, { useStore } from "../store/basic-store";
import { User } from "../types";
import { useEffect, useState } from "react";
import { sendEmailCode } from "../service/auth";

export function MainPage() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const emailVerifiedShown = useStore("emailVerifiedShown");
  const user = useStore<User>("user");

  useEffect(() => {
    let emailSent = false;
    if (
      user &&
      !user?.email_verified &&
      (!user.email_code_timeout || Date.now() < user.email_code_timeout)
    ) {
      emailSent = true;
    }

    if (user && !emailVerifiedShown && !isModalOpen && !emailSent) {
      store.set("emailVerifiedShown", true);
      setIsModalOpen(true);
    }
  }, [user, isModalOpen, emailVerifiedShown]);

  const handleOk = () => {
    setIsModalOpen(false);
    sendEmailCode();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Layout>
      <Header>
        <h1>Fractured Nations Games</h1>
      </Header>
      <Content>
        <HeaderMenu selected="home" />
        <Content>
          <Modal
            title="Email not Verified"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                No
              </Button>,
              <Button key="submit" type="primary" onClick={handleOk}>
                Yes
              </Button>,
            ]}
          >
            <p>Would you like to verify your email?</p>
          </Modal>
          {/* <h2>Be a high prince! Win Glory. Win honor. Feel the thrill.</h2> */}
          {user ? (
            <Row>
              <Col span={24}>
                <Card
                  title="Tic-Tac-Toe"
                  style={{ width: 300, margin: "auto" }}
                  onClick={() => navigate("/games/tic-tac-toe")}
                >
                  Whether is called Knots and Crosses. Or X's and O's. Its the
                  same. Get 3 in a row and you win.
                </Card>
              </Col>
            </Row>
          ) : (
            "Please Log In To play"
          )}
        </Content>
      </Content>
    </Layout>
  );
}
