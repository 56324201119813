import Layout, { Content, Header } from "antd/es/layout/layout";
import { HeaderMenu } from "../components/header-menu";
import store, { useStore } from "../store/basic-store";
import { GameStore, User, ZoneId } from "../types";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

interface MyGame {
  id: string;
  type: ZoneId;
}

export function MyGames() {
  const myGames = useStore<MyGame[]>("my-games");
  const user = useStore<User>("user");
  const nav = useNavigate();

  console.log("my-games: ", myGames);
  const games: GameStore[] = (myGames || [])
    .map((g) => {
      return store.get(`game-${g.id}`);
    })
    .filter((g) => !!g);

  console.log("games: ", games);

  if (!user?.id) {
    return;
  }

  const columns: ColumnsType<GameStore> = [
    {
      title: "Name",
      key: "name",
      width: "30%",
      render: (game: GameStore) => <>{game.name}</>,
    },
    {
      title: "Description",
      key: "Description",
      width: "40%",
      render: (game: GameStore) => <>{game.description}</>,
    },
    {
      title: "Players",
      key: "Players",
      width: "20%",
      render: (game: GameStore) => {
        return <>{game.players.map((player) => player.username).join(", ")}</>;
      },
    },
    {
      title: "Turn",
      key: "Turn",
      width: "10%",
      render: (game: GameStore) => <MyTurn id={game.id} userId={user?.id} />,
    },
  ];

  return (
    <Layout>
      <Header>
        <h1>Fractured Nations Games</h1>
      </Header>
      <Content>
        <HeaderMenu selected="my-games" />
        <Content>
          <h3>My Games</h3>
          <Table
            onRow={(record) => {
              return {
                onClick: async (e) => {
                  nav(`/games/tic-tac-toe/game/${record.id}`);
                },
              };
            }}
            columns={columns}
            dataSource={games}
            style={{ margin: "0 2rem" }}
          />
        </Content>
      </Content>
    </Layout>
  );
}

export function MyTurn(params: { id: string; userId: number }) {
  const game = useStore<GameStore>(`game-${params.id}`);
  let state = "In Progress";
  if (!game?.started) {
    state = "Not Started";
  } else if (game?.won) {
    const winner = game.players.find((p) => p.id === game?.won);
    if (winner) {
      state = `${winner.playerName || winner.username} Won!`;
    } else {
      state = "Tie Game";
    }
  } else if (game?.playerTurn === params.userId) {
    state = "Your Turn";
  } else if (game?.playerTurn) {
    const player = game.players.find((p) => p.id === game.playerTurn);
    if (player) {
      state = `${player.playerName || player.username}'s Turn`;
    }
  }
  return <div>{state}</div>;
}
