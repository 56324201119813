import { Button, Form, Input, Layout, List, Modal } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Header } from "antd/es/layout/layout";
import { useEffect, useRef, useState } from "react";
import {
  CreateGamePayload,
  ZoneId,
  GameProps,
  MessageStreamStore,
  PlayerInfo,
  User,
} from "../types";
import { useStore } from "../store/basic-store";
import {
  joinGameZoneAction,
  sendGameInvite,
  createGame,
} from "../service/game.action";
import {
  getMessageAction,
  sendFriendRequest,
  sendMessageAction,
} from "../service/messaging.action";
import TextArea from "antd/es/input/TextArea";
import { DraggableModel } from "./dragable-model";
import { useOnLoad } from "../hooks/lifecycle";
import { BackHome } from "./back-home";

export function GameLobbyLayout(props: GameProps) {
  const { name, children, id } = props;

  const [openAction, setActionOpen] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openCreateGame, setOpenCreateGame] = useState(false);

  const [actionTarget, setActionTarget] = useState<number>();
  const userList = useStore<User[]>("user-list");
  const user = useStore<User>("user");
  const dataSource = userList || [];
  const found = dataSource.find((u) => u.id === user?.id);
  if (!found && user) {
    dataSource.push(user);
  }

  useOnLoad(() => {
    if (user) {
      joinGameZoneAction(id);
    }
  });

  const handleCancel = () => {
    setActionOpen(false);
  };

  const handleUserClick = (id: number) => {
    if (user?.id !== id) {
      setActionOpen(true);
      setActionTarget(id);
    }
  };

  const handleOpenMessage = () => {
    if (user) {
      getMessageAction({
        timestamp: Date.now(),
        source: { username: user?.username, id: user.id },
        target: { personId: actionTarget, zone: id },
      });
    }
    setActionOpen(false);
    if (actionTarget) {
      setOpenMessage(true);
    }
  };

  const handleInvite = () => {
    sendGameInvite();
  };

  const handleFriendInvite = () => {
    sendFriendRequest();
  };

  const handleCreateGame = () => {
    // Open message
    setOpenCreateGame(true);
    // createGameAction
  };

  const getUsername = () => {
    const user = dataSource.find((user) => user.id === actionTarget);
    return user?.username || "";
  };
  return (
    <Layout>
      <CreateGame
        open={openCreateGame}
        handleCreate={(props: CreateGameFieldsType) => {
          const { name, description } = props;
          if (user && name) {
            const owner: PlayerInfo = { id: user.id, username: user.username };
            const payload: CreateGamePayload = {
              type: id,
              name,
              description: description || "Knots ad Crosses",
              owner,
              players: [owner],
            };
            createGame(payload);
            setOpenCreateGame(false);
          }
        }}
        handleCancel={() => setOpenCreateGame(false)}
      />
      <Modal
        open={openAction}
        title={`User Action to ${getUsername()}`}
        onCancel={handleCancel}
        footer={[
          <Button key="message" onClick={handleOpenMessage}>
            Message
          </Button>,
          <Button key="invite" onClick={handleInvite}>
            Invite to game
          </Button>,
          <Button key="friend" onClick={handleFriendInvite}>
            Send Friend Request
          </Button>,
        ]}
      ></Modal>
      <Layout>
        <Header>
          <h2>{name}</h2>
          <BackHome zoneId={id} />
        </Header>
        <Layout>
          <Content>{children}</Content>
          {openMessage && actionTarget && user?.id ? (
            <MessageSider
              actionTarget={actionTarget}
              getUsername={getUsername}
              setOpenMessage={setOpenMessage}
              id={id}
              open={openMessage}
              userId={user.id.toString()}
            />
          ) : null}
        </Layout>
      </Layout>
      {user ? (
        <OnlineUsers
          dataSource={dataSource}
          handleUserClick={handleUserClick}
          handleCreateGame={handleCreateGame}
        />
      ) : null}
    </Layout>
  );
}

function MessageSider(props: {
  actionTarget: number;
  getUsername: () => string;
  setOpenMessage: (bool: boolean) => void;
  id: ZoneId;
  open: boolean;
  userId: string;
}) {
  const { actionTarget, getUsername, setOpenMessage, id, open, userId } = props;
  const [message, setMessage] = useState("");
  const idStr = [actionTarget, userId].sort().join("-");
  const messageStream = useStore<MessageStreamStore>(`messages.ids_${idStr}`);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    // @ts-ignore
    if (messagesEndRef?.current && messagesEndRef?.current.scrollIntoView) {
      // @ts-ignore
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messageStream]);
  return (
    <DraggableModel
      setOpen={setOpenMessage}
      open={open}
      header={<h4>Message with {getUsername()}</h4>}
      footer={[
        <Content>
          <TextArea
            name="message-input"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            allowClear
            autoSize={{ minRows: 1, maxRows: 5 }}
            style={{ margin: ".5rem 0" }}
          />

          <Button
            onClick={() => {
              const val = message;
              setMessage("");
              if (val) {
                sendMessageAction({
                  message: val,
                  timestamp: Date.now(),
                  target: { personId: actionTarget, zone: id },
                });
              }
            }}
          >
            Send
          </Button>
        </Content>,
      ]}
    >
      <Layout>
        <div
          style={{
            maxHeight: "20vh",
            scrollbarColor: "green",
            overflowY: "scroll",
          }}
        >
          <List
            dataSource={messageStream?.messages}
            renderItem={(item) => {
              return item.source?.username && item.message ? (
                <Content style={{ textAlign: "left", padding: "0 1rem" }}>
                  <p>
                    <b>{item.source?.username}: </b>
                    {item.message}
                  </p>
                </Content>
              ) : null;
            }}
          />
          <div ref={messagesEndRef} />
        </div>
      </Layout>
    </DraggableModel>
  );
}

function OnlineUsers(props: {
  handleCreateGame: () => void;
  dataSource: User[];
  handleUserClick: (id: number) => void;
}) {
  const { dataSource, handleUserClick, handleCreateGame } = props;

  return (
    <Sider className="online-users gray-theme">
      <List
        dataSource={dataSource}
        renderItem={(user: User) => (
          <List.Item
            onClick={() => handleUserClick(user.id)}
            style={{
              justifyContent: "center",
              background: "transparent",
              color: "#fff",
              textTransform: "capitalize",
            }}
          >
            {user.username}
          </List.Item>
        )}
        header={<h4>Online</h4>}
        footer={<Button onClick={() => handleCreateGame()}>Create Game</Button>}
      />
    </Sider>
  );
}

type CreateGameFieldsType = {
  name?: string;
  description?: string;
};

export function CreateGame({
  handleCancel,
  handleCreate,
  open,
}: {
  open?: boolean;
  handleCreate: (val: CreateGameFieldsType) => void;
  handleCancel: () => void;
}) {
  const [form] = Form.useForm();
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    // TODO Handle Error
    handleCancel();
  };

  const onFinish = (vals: CreateGameFieldsType) => {
    handleCreate({ ...vals });
    form.resetFields();
  };
  return (
    <Modal
      open={open}
      onCancel={() => handleCancel()}
      title="Create Game"
      footer={[]}
    >
      <Form
        form={form}
        name="create-game"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item<CreateGameFieldsType>
          label="Game Name"
          name="name"
          rules={[{ required: true, message: "Please a game name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item<CreateGameFieldsType>
          label="Description"
          name="description"
          initialValue="Knots and Crosses"
          rules={[
            { required: false, message: "Please input a game description!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
