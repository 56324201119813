import React, { ReactNode, useRef, useState } from "react";
import type { DraggableData, DraggableEvent } from "react-draggable";
import Draggable from "react-draggable";
import { Modal } from "antd";

export function DraggableModel({
	children,
	footer,
	header,
	open,
	onOk,
	onCancel,
	setOpen
}: {
	children: ReactNode;
	footer?: ReactNode[];
	header: ReactNode;
	open: boolean;
	onOk?: (e: React.MouseEvent<HTMLElement>) => void;
	onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
	setOpen: (o: boolean) => void;
}) {
	const [disabled, setDisabled] = useState(true);
	const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
	const draggleRef = useRef<HTMLDivElement>(null);

	const handleOk = (e: React.MouseEvent<HTMLElement>) => {
		setOpen(false);
		if (onOk) {
			onOk(e);
		}
	};

	const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
		setOpen(false);
		if (onCancel) {
			onCancel(e);
		}
	};

	const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
		const { clientWidth, clientHeight } = window.document.documentElement;
		const targetRect = draggleRef.current?.getBoundingClientRect();
		if (!targetRect) {
			return;
		}
		setBounds({
			left: -targetRect.left + uiData.x,
			right: clientWidth - (targetRect.right - uiData.x),
			top: -targetRect.top + uiData.y,
			bottom: clientHeight - (targetRect.bottom - uiData.y)
		});
	};

	return (
		<Modal
			title={
				<div
					style={{
						width: "100%",
						cursor: "move"
					}}
					onMouseOver={() => {
						if (disabled) {
							setDisabled(false);
						}
					}}
					onMouseOut={() => {
						setDisabled(true);
					}}
					// fix eslintjsx-a11y/mouse-events-have-key-events
					// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
					onFocus={() => {}}
					onBlur={() => {}}
					// end
				>
					{header}
				</div>
			}
			open={open}
			onOk={handleOk}
			onCancel={handleCancel}
			modalRender={(modal) => (
				<Draggable
					disabled={disabled}
					bounds={bounds}
					nodeRef={draggleRef}
					onStart={(event, uiData) => onStart(event, uiData)}
				>
					<div ref={draggleRef}>{modal}</div>
				</Draggable>
			)}
			footer={footer}
		>
			{children}
		</Modal>
	);
}
