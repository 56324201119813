import { CaretLeftFilled } from "@ant-design/icons";
import { FloatButton } from "antd";
import { useNavigate } from "react-router-dom";
import { leaveGameZoneAction } from "../service/game.action";
import { ZoneId } from "../types";

export function BackHome({ zoneId, url }: { url?: string; zoneId?: ZoneId }) {
  const nav = useNavigate();

  const leaveZone = () => {
    if (zoneId) {
      leaveGameZoneAction(zoneId);
    }

    nav(url || "/");
  };

  return (
    <FloatButton
      icon={<CaretLeftFilled />}
      shape="circle"
      style={{ left: "1.7rem", top: "1.7rem" }}
      onClick={() => leaveZone()}
    />
  );
}
