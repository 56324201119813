import { Button, Form, Input, Layout, Modal } from "antd";
import { Header, Content } from "antd/es/layout/layout";
import { HeaderMenu } from "../components/header-menu";
import { useEffect, useState } from "react";
import { FieldData, ValidateErrorEntity } from "rc-field-form/lib/interface";
import { signUpAction } from "../service/auth";
import { AuthBody, ServicePayload, SignUpPayload } from "../types";
import { useNavigate } from "react-router-dom";
import store, { useStore } from "../store/basic-store";

export function SignUpPage() {
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const auth = useStore<ServicePayload<AuthBody>>("auth");
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (auth?.error) {
      console.log("useEffect err: ", auth?.error);
      onError();
    } else if (auth?.data && !auth?.error) {
      navigate("/");
    }
  }, [auth]);

  const onError = () => {
    modal.error({
      title: "Invalid Login",
      content: `Invalid login attempt. ${auth?.error?.message}. Please try again.`,
      onOk: () => {
        console.log("On ok");
        store.setApi({ key: "auth", loading: false });
      },
    });
  };

  const onChange = (_: FieldData[], allFields: FieldData[]) => {
    const hasErrors = !!allFields.find(({ errors }) => errors?.length);
    setDisabled(hasErrors);
  };

  const onFinish = (payload: SignUpPayload) => {
    console.log("values: ", payload);

    signUpAction(payload);
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity<SignUpPayload>) => {
    console.log("onFinishFailed errorInfo: ", errorInfo);
  };

  return (
    <Layout>
      <Header>
        <h1>Sign Up</h1>
      </Header>
      <Content>
        {contextHolder}
        <HeaderMenu selected="login" />
        <Content>
          <Form
            name="login"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            autoComplete="off"
            onFieldsChange={onChange}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item<SignUpPayload>
              label="Username"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
                { min: 3, message: "Username must be 3 or more characters" },
              ]}
              validateTrigger={["onBlur"]}
            >
              <Input />
            </Form.Item>

            <Form.Item<SignUpPayload>
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
              validateTrigger={["onBlur"]}
            >
              <Input />
            </Form.Item>

            <Form.Item<SignUpPayload>
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
                { min: 8, message: "Password must be 8 or more characters" },
              ]}
              validateTrigger={["onBlur"]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item<SignUpPayload>
              label="Password Verify"
              name="password2"
              dependencies={["password"]}
              hasFeedback
              rules={[
                { required: true, message: "Please input your password again" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
              validateTrigger={["onChange", "onBlur"]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" disabled={disabled}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </Content>
    </Layout>
  );
}
