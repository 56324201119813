import { ReactNode } from "react";
import { JsonObj, MessagePayload } from "./general";

export enum ZoneId {
  TIC_TAC_TOE = "TIC_TAC_TOE",
}

export interface GameProps {
  id: ZoneId;
  name: string;
  children: ReactNode;
}

export interface PlayerInfo {
  id: number;
  username: string;
  playerName?: string;
}

export interface ZoneUser {
  id: number;
  username: string;
  lastCall: number;
}

export type DataIndex = keyof GameStore;

export interface CreateGamePayload<O = JsonObj> {
  type: ZoneId;
  name: string;
  owner: PlayerInfo;
  description: string;
  players: PlayerInfo[];
  options?: O;
}

export interface ZoneStore {
  lobby: GameLobbyStore;
  users: ZoneUser[];
}

export interface GameStore<O = JsonObj> {
  description: string;
  id: string;
  lastUpdated: number;
  lastTurn?: number;
  messages?: MessagePayload[];
  name: string;
  options?: O;
  owner: PlayerInfo;
  players: PlayerInfo[];
  playerTurn?: number;
  started?: boolean;
  type: ZoneId;
  turnLimit?: number; // milliseconds
  won?: number | "CAT";
}

export interface ThreeSquare {
  "1": number;
  "2": number;
  "3": number;
}

export enum BoardKey {
  A = "A",
  B = "B",
  C = "C",
}

export interface Board3X3 {
  [BoardKey.C]: ThreeSquare;
  [BoardKey.B]: ThreeSquare;
  [BoardKey.A]: ThreeSquare;
}

export interface TicTakToeGame extends GameStore {
  board: Board3X3;
  options?: { goFirst: boolean; xoValue: "X" | "O"; turnTimer: TurnTimer };
}

export interface GameLobbyStore {
  gameId: ZoneId;
  games: GameStore[];
  lastUpdated: number;
}

export type TurnInterval = "MINUTE" | "HOUR" | "DAY";

export interface TurnTimer {
  duration: number;
  interval: TurnInterval;
}
