import { Button, Checkbox, Form, Input, Layout, Modal } from "antd";
import { Header, Content } from "antd/es/layout/layout";
import { HeaderMenu } from "../components/header-menu";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FieldData, ValidateErrorEntity } from "rc-field-form/lib/interface";
import store, { useStore } from "../store/basic-store";
import { AuthBody, ServicePayload } from "../types";
import { loginAction } from "../service/auth";

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};

export function LoginPage() {
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const auth = useStore<ServicePayload<AuthBody>>("auth");
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (auth?.error) {
      console.log("useEffect err: ", auth?.error);
      onError();
    } else if (auth?.data && !auth?.error) {
      navigate("/");
    }
  }, [auth]);

  const onError = () => {
    modal.error({
      title: "Invalid Login",
      content: "Invalid login attempt. please try again.",
      onOk: () => {
        console.log("On ok");
        store.setApi({ key: "auth", loading: false });
      }
    });
  };

  const onChange = (_: FieldData[], allFields: FieldData[]) => {
    const hasErrors = !!allFields.find(({ errors }) => errors?.length);
    setDisabled(hasErrors);
  };

  const onFinish = async (values: FieldType) => {
    const { username, password } = values;
    if (username && password) {
      loginAction({ username, password });
    }
  };

  const onFinishFailed = (errorInfo: ValidateErrorEntity<FieldType>) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout>
      <Header>
        <h1>Login</h1>
      </Header>
      <Content>
        {contextHolder}
        <HeaderMenu selected="login" />
        <Content>
          <Form
            name="login"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onFieldsChange={onChange}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label="Username"
              name="username"
              rules={[{ required: true, message: "Please input your username!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item<FieldType>
              label="Password"
              name="password"
              rules={[{ required: true, message: "Please input your password!" }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item<FieldType> name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
              <Button
                type="default"
                htmlType="button"
                style={{ margin: "0 3rem" }}
                onClick={() => navigate("/sign-up")}
              >
                Sign up
              </Button>
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" disabled={disabled}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </Content>
    </Layout>
  );
}
