import {
  Alert,
  Button,
  Col,
  Divider,
  Flex,
  Input,
  Layout,
  Radio,
  RadioChangeEvent,
  Row,
  Select,
  Space,
} from "antd";
import { Content, Header } from "antd/es/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import { useOnLoad } from "../../hooks/lifecycle";
import {
  forFeitAction,
  getGame,
  sendGameMessageAction,
  startGame,
  takeTurnAction,
} from "../../service/game.action";
import {
  BoardKey,
  ZoneId,
  MessagePayload,
  MessageStreamStore,
  MessageTarget,
  PlayerInfo,
  TicTakToeGame,
  User,
  TurnTimer,
  TurnInterval,
} from "../../types";
import { useStore } from "../../store/basic-store";
import "./tic-tac-toe.css";
import { ChangeEvent, useEffect, useState } from "react";
import { BackHome } from "../../components/back-home";
import { CloseOutlined } from "@ant-design/icons";
import { MessageBox } from "../../components/messaging/message-box";
import { useTimer } from "../../components/timer";

const WinnerRowA = () => <div className="winnerRow winA"></div>;
const WinnerRowB = () => <div className="winnerRow winB"></div>;
const WinnerRowC = () => <div className="winnerRow winC"></div>;
const WinnerRow1 = () => <div className="winnerRow win1"></div>;
const WinnerRow2 = () => <div className="winnerRow win2"></div>;
const WinnerRow3 = () => <div className="winnerRow win3"></div>;
const WinnerRowDia1 = () => <div className="winnerRow winDia1"></div>;
const WinnerRowDia2 = () => <div className="winnerRow winDia2"></div>;

function Square(params: {
  id: string;
  callback: (id: string) => void;
  value?: "X" | "O" | "";
}) {
  const { id, callback, value } = params;
  return (
    <div className="Tic-Tac-Toe-Square" onClick={() => callback(id)}>
      {value === "X" ? <Exs /> : null}
      {value === "O" ? <Oos /> : null}
    </div>
  );
}

function getSquareValue(square: string, game: TicTakToeGame): "X" | "O" | "" {
  const { board, options, players } = game;
  const [player1] = players;
  const { xoValue } = options || { goFirst: true, xoValue: "X" };
  const [row, col] = square.split("") as [BoardKey, "1" | "2" | "3"];
  const val = board[row][col];
  if (!val) {
    return "";
  }
  const player2Val = xoValue === "X" ? "O" : "X";
  return val === player1?.id ? xoValue : player2Val;
}

function getWinnerRow(
  game: TicTakToeGame
):
  | ""
  | "winA"
  | "winB"
  | "winC"
  | "win1"
  | "win2"
  | "win3"
  | "winDia1"
  | "winDia2" {
  const { won, board } = game;
  const wonId = won;
  if (!wonId) {
    return "";
  }
  if (board.A[1] === won && board.A[2] === won && board.A[3] === won) {
    return "winA";
  }
  if (board.B[1] === won && board.B[2] === won && board.B[3] === won) {
    return "winB";
  }
  if (board.C[1] === won && board.C[2] === won && board.C[3] === won) {
    return "winC";
  }
  if (board.A[1] === won && board.B[1] === won && board.C[1] === won) {
    return "win1";
  }
  if (board.A[2] === won && board.B[2] === won && board.C[2] === won) {
    return "win2";
  }
  if (board.A[3] === won && board.B[3] === won && board.C[3] === won) {
    return "win3";
  }
  if (board.A[3] === won && board.B[2] === won && board.C[1] === won) {
    return "winDia1";
  }
  if (board.A[1] === won && board.B[2] === won && board.C[3] === won) {
    return "winDia2";
  }
  return "";
}

function Board({
  game,
  handleClick,
}: {
  game: TicTakToeGame;
  handleClick: (square: string) => void;
}) {
  const wonRow = game?.won && getWinnerRow(game);
  return (
    <Content className="board">
      {wonRow === "winA" ? <WinnerRowA /> : null}
      {wonRow === "winB" ? <WinnerRowB /> : null}
      {wonRow === "winC" ? <WinnerRowC /> : null}
      {wonRow === "win1" ? <WinnerRow1 /> : null}
      {wonRow === "win2" ? <WinnerRow2 /> : null}
      {wonRow === "win3" ? <WinnerRow3 /> : null}
      {wonRow === "winDia1" ? <WinnerRowDia1 /> : null}
      {wonRow === "winDia2" ? <WinnerRowDia2 /> : null}

      <Row gutter={[8, 8]}>
        <Col span={8} className="c1">
          <Square
            id="C1"
            callback={handleClick}
            value={getSquareValue("C1", game)}
          />
        </Col>
        <Col span={8} className="c2">
          <Square
            id="C2"
            callback={handleClick}
            value={getSquareValue("C2", game)}
          />
        </Col>
        <Col span={8} className="c3">
          <Square
            id="C3"
            callback={handleClick}
            value={getSquareValue("C3", game)}
          />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={8} className="b1">
          <Square
            id="B1"
            callback={handleClick}
            value={getSquareValue("B1", game)}
          />
        </Col>
        <Col span={8} className="b2">
          <Square
            id="B2"
            callback={handleClick}
            value={getSquareValue("B2", game)}
          />
        </Col>
        <Col span={8} className="b3">
          <Square
            id="B3"
            callback={handleClick}
            value={getSquareValue("B3", game)}
          />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={8} className="a1">
          <Square
            id="A1"
            callback={handleClick}
            value={getSquareValue("A1", game)}
          />
        </Col>
        <Col span={8} className="a2">
          <Square
            id="A2"
            callback={handleClick}
            value={getSquareValue("A2", game)}
          />
        </Col>
        <Col span={8} className="a3">
          <Square
            id="A3"
            callback={handleClick}
            value={getSquareValue("A3", game)}
          />
        </Col>
      </Row>
    </Content>
  );
}

export function TicTacToeGameBoard() {
  const { id } = useParams();
  const nav = useNavigate();
  const [yourTurn, setYourTurn] = useState(false);
  console.log("id: ", id);
  const game = useStore<TicTakToeGame>(`game-${id}`);
  const user = useStore<User>("user");
  const [xoValue, setXOValue] = useState<"X" | "O">("X");
  const [goFirst, setGoFirst] = useState<boolean>(true);
  const [turnTimer, setTurnTimer] = useState<TurnTimer>({
    duration: 2,
    interval: "MINUTE",
  });
  const [notYourTurn, setNotYourTurn] = useState(false);
  const [notStarted, setNotStarted] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [gameWon, setGameWon] = useState(game?.won);
  const endTime =
    game?.lastTurn && game.turnLimit && !game?.won
      ? game.lastTurn + game.turnLimit
      : 0;
  const [count] = useTimer({
    endTime,
  });
  const canStart = !game?.started && game?.players.length === 2;
  const [player1, player2] = game?.players || [];
  const whoWon =
    game?.won === "CAT"
      ? ({ username: "CAT" } as PlayerInfo)
      : game?.players.find((p) => p.id === game.won);

  useEffect(() => {
    if (game?.started) {
      setNotStarted(false);
      setXOValue(game.options?.xoValue || "X");
    }
    if (game?.playerTurn === user?.id) {
      setYourTurn(true);
      setNotYourTurn(false);
    } else {
      setYourTurn(false);
    }
  }, [user, game]);

  useOnLoad(() => {
    if (id) {
      getGame(id, ZoneId.TIC_TAC_TOE);
    }
  });

  const handleClick = (square: string) => {
    if (game?.players.length === 1) {
      setWaiting(true);
    } else if (!game?.started) {
      setNotStarted(true);
    } else if (game?.won) {
      setGameWon(game?.won);
    } else if (game?.playerTurn !== user?.id) {
      setNotYourTurn(true);
    } else if (!yourTurn) {
      setNotYourTurn(true);
    } else if (game && user) {
      // Handle turn Action Game Event
      setYourTurn(false);
      const [row, col] = square.split("");
      const num = parseInt(col, 10) as 1 | 2 | 3;
      // @ts-ignore
      game.board[row][num] = user.id;
      takeTurnAction(game.id, game.type, { row, col, id: user.id });
    }
  };

  const onXOChange = (e: RadioChangeEvent) => {
    setXOValue(e.target.value);
  };

  const onGoFirstChange = (e: RadioChangeEvent) => {
    setGoFirst(e.target.value);
  };

  const handleStart = () => {
    const options = { xoValue, goFirst, turnTimer };
    if (game) {
      startGame(game.id, game.type, options);
    } else {
      throw new Error("Invalid game");
    }
  };

  const messageStream = {
    messages: game?.messages || [],
  } as MessageStreamStore;

  const messageTarget = {
    zone: ZoneId.TIC_TAC_TOE,
    room: game?.id,
  } as MessageTarget;

  const onSendMessage = (payload: MessagePayload) => {
    if (game && user) {
      sendGameMessageAction(game.id, ZoneId.TIC_TAC_TOE, {
        ...payload,
        source: { username: user.username, id: user.id },
      });
    }
  };

  const handleTurnDurationChange = (e: ChangeEvent<{ value: number }>) => {
    if (e.target?.value) {
      setTurnTimer({ ...turnTimer, duration: e.target?.value });
    }
  };

  const handleTurnIntervalChange = (value: TurnInterval) => {
    if (value) {
      setTurnTimer({ ...turnTimer, interval: value });
    }
  };

  return (
    <Layout>
      <Header>
        <h2>Tic Tac Toe</h2>
        <BackHome url="/games/tic-tac-toe" />
      </Header>
      {yourTurn &&
      endTime === 0 &&
      !game?.won &&
      game?.started &&
      game?.lastTurn ? (
        <Alert
          message="Times up! Take your turn quick. Your opponent can accept your game forfeit and win."
          type="error"
          closable
          onClose={() => setNotStarted(false)}
        />
      ) : null}
      {notStarted ? (
        <Alert
          message="Game Not Started"
          type="warning"
          closable
          onClose={() => setNotStarted(false)}
        />
      ) : null}
      {notYourTurn ? (
        <Alert
          message="Its not your turn"
          type="warning"
          closable
          onClose={() => setNotYourTurn(false)}
        />
      ) : null}
      {waiting ? (
        <Alert
          message="Waiting on another player"
          type="warning"
          closable
          onClose={() => setWaiting(false)}
        />
      ) : null}
      {gameWon && whoWon ? (
        <Alert
          message={`Game Over! ${whoWon?.playerName || whoWon?.username} Wins.`}
          type="success"
          closable
          onClose={() => setGameWon(undefined)}
        />
      ) : null}
      <Content
        style={{ overflow: "hidden", display: "flex" }}
        className="GameBox"
      >
        <Content
          className="GameState"
          style={{
            overflowY: "scroll",
            flex: "10 2 auto",
          }}
        >
          <Flex
            className="Tic_Tac_Toe-board"
            justify="center"
            align="center"
            flex="1 1 auto"
            style={{ columnGap: "15px", alignItems: "flex-start" }}
          >
            {user && game ? (
              <Player player={player1} user={user} game={game} count={count} />
            ) : null}
            <Flex>
              <div>
                {game?.lastTurn ? <div>Time Left {count} seconds</div> : null}
                {game ? <Board handleClick={handleClick} game={game} /> : null}
              </div>
            </Flex>
            {user && game ? (
              <Player player={player2} user={user} game={game} count={count} />
            ) : null}
          </Flex>
          <Flex className="gameState" flex="1 1 auto" justify="center">
            {!player2 ? <div>Waiting on another player</div> : null}
            {user?.id === game?.owner?.id && !game?.started && player2 ? (
              <Flex
                flex="1 1 auto"
                vertical
                style={{ textAlign: "center", marginTop: "1rem" }}
              >
                <div>
                  <h4>Choose X or O</h4>
                  <Radio.Group onChange={onXOChange} value={xoValue}>
                    <Radio value={"X"}>X</Radio>
                    <Radio value={"O"}>O</Radio>
                  </Radio.Group>
                </div>
                <Divider dashed />
                <div>
                  <h4>Turn Timer</h4>
                  <p>
                    Description: How long before turn expires, and game is
                    forfeit.
                  </p>
                  <div>
                    <Flex flex="1 1 auto" align="center" justify="center">
                      <Input
                        type="number"
                        max="100"
                        min="1"
                        defaultValue={2}
                        // @ts-ignore
                        onChange={handleTurnDurationChange}
                        style={{ maxWidth: "4rem" }}
                      />
                      <Select
                        defaultValue="MINUTE"
                        style={{ width: 120 }}
                        onChange={handleTurnIntervalChange}
                        options={[
                          { value: "DAY", label: "Days" },
                          { value: "HOUR", label: "Hours" },
                          { value: "MINUTE", label: "Minutes" },
                        ]}
                      />
                    </Flex>
                  </div>
                </div>
                <Divider dashed />
                <div style={{ paddingBottom: "1rem" }}>
                  <h4>Choose to go first</h4>
                  <Radio.Group onChange={onGoFirstChange} value={goFirst}>
                    <Radio value={true}>Go First</Radio>
                    <Radio value={false}>Go Last</Radio>
                  </Radio.Group>
                </div>
                <Divider dashed />
                <Space.Compact
                  size="large"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: "4rem",
                  }}
                >
                  <Button disabled={!canStart} onClick={handleStart}>
                    Start
                  </Button>
                </Space.Compact>
              </Flex>
            ) : null}
            {game?.won && whoWon ? (
              <div>
                <h3>Game Over</h3>
                {game?.won === "CAT" ? (
                  <h5>Tie Game</h5>
                ) : (
                  <h5>{whoWon?.playerName || whoWon.username} Wins!</h5>
                )}
                <Button onClick={() => nav("/games/tic-tac-toe")}>Exit</Button>
              </div>
            ) : null}
            {user?.id !== game?.owner?.id && !game?.started ? (
              <div>
                <h4>Game Not Started</h4>
                <h5>You are O</h5>
                <h5>You go last</h5>
              </div>
            ) : null}
          </Flex>
        </Content>

        <Content
          className="messages"
          style={{
            border: "1px solid #ccc",
            padding: "0 1rem",
            maxHeight: "100%",
          }}
        >
          <h4>Messages</h4>
          <Divider />
          <MessageBox
            messageStream={messageStream}
            sendMessageAction={onSendMessage}
            target={messageTarget}
          />
        </Content>
      </Content>
    </Layout>
  );
}

function callForfit(gameId: string) {
  forFeitAction(gameId, ZoneId.TIC_TAC_TOE);
}

function YourTurn() {
  return (
    <div>
      <div style={{ color: "green", fontWeight: "bold" }}>Its Your Turn</div>
    </div>
  );
}

function Exs() {
  return (
    <CloseOutlined
      style={{ height: "5rem", width: "4rem", fontSize: "4rem", color: "#ddd" }}
    />
  );
}
function Oos() {
  return <div className="Oos" />;
}

function ForFeit({ gameId }: { gameId: string }) {
  return (
    <div>
      <Divider />
      <div style={{ color: "red" }}>
        Your opponents time is up. Would you like to win by default?
      </div>
      <Button type="primary" danger onClick={() => callForfit(gameId)}>
        Force Win
      </Button>
    </div>
  );
}

function Player({
  count,
  player,
  game,
  user,
}: {
  player?: PlayerInfo;
  game: TicTakToeGame;
  user: User;
  count: number;
}) {
  const [timesUp, setTimesUp] = useState(false);
  const {
    players,
    options,
    started,
    won,
    playerTurn,
    lastTurn,
    turnLimit,
    id,
  } = game;

  const inProgresss = started && !won;
  const player1 = players[0];
  const { xoValue } = options || {};
  const isplayer1 = player1?.id === player?.id;
  const isUser = user.id === player?.id;
  let displayXo = "X";
  if (!xoValue) {
    if (!isplayer1) {
      displayXo = "O";
    }
  } else {
    if (isplayer1) {
      displayXo = xoValue;
    } else {
      displayXo = xoValue === "X" ? "O" : "X";
    }
  }
  const yourTurn = inProgresss && isUser && playerTurn === user.id;

  useEffect(() => {
    const now = Date.now();
    const nextTurn = turnLimit && lastTurn ? lastTurn + turnLimit : now + 1;

    const isTimesUp = !!(inProgresss && lastTurn && nextTurn - now < 1000);
    setTimesUp(isTimesUp);
  }, [count, inProgresss, lastTurn, turnLimit]);

  return (
    <div className="player">
      <div>Player {displayXo}</div>
      <div>{player?.username}</div>
      {yourTurn ? <YourTurn /> : null}
      {inProgresss && !yourTurn && timesUp && isUser ? (
        <ForFeit gameId={id} />
      ) : null}
      {inProgresss && yourTurn && timesUp && isUser ? (
        <div style={{ color: "red", fontWeight: "bolder" }}>Times Up</div>
      ) : null}
    </div>
  );
}
